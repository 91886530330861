import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { getElevationAlerts } from '../../services/alerts.services';
import {
  ElevationAlertsAtom,
  ElevationAlertsFilters
} from '../../state/alerts.atom';

export const elevationMethodsArray = ['bcs', 'bm', 'bcp'];

const useElevationAlerts = (tab: number, elevationMethodId: number) => {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 15;
  const filtersState = useRecoilValue(ElevationAlertsAtom);
  const [filteredData, setFilteredData] =
    useState<ElevationAlertsFilters>(filtersState);

  const intervalOptions = ['1', '2', '7'];

  const getData = async () => {
    try {
      const res = await getElevationAlerts(
        currentPage,
        perPage,
        filteredData,
        elevationMethodsArray[elevationMethodId],
        intervalOptions[tab]
      );

      return {
        meta: res.meta,
        data: res.data?.map((it: any) => ({
          ...it
        }))
      };
    } catch (e) {
      toast.error('Erro ao buscar dados');
      throw new Error('Erro ao buscar dados');
    }
  };

  const { data, isFetching, isLoading } = useQuery({
    queryFn: getData,
    queryKey: [
      'ElevationAlerts',
      currentPage,
      filteredData,
      tab,
      elevationMethodId
    ],
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false
  });

  const handleFilter = (isClearing?: boolean) => {
    setCurrentPage(1);
    !isClearing
      ? setFilteredData(old => ({
          ...filtersState
        }))
      : setFilteredData({
          field: [],
          status: [],
          criticity: [],
          alertType: [],
          station: [],
          oilwell: [],
          timeRange: {
            endDate: undefined,
            startDate: undefined
          }
        });
  };

  return {
    data: data ?? null,
    isFetching,
    handleFilter,
    isLoading,
    setCurrentPage,
    perPage,
    filteredData,
    setFilteredData,
    currentPage
  };
};

export default useElevationAlerts;
