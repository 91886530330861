import Button from '@/components/atoms/Button';
import H1 from '@/components/atoms/H1';
import { LabelProps } from '@/components/atoms/Label';
import LateClock, { LateOptions } from '@/components/atoms/LateClock';
import { PageWrapper } from '@/components/atoms/PageWrapper';
import { PrintStyle } from '@/components/atoms/PrintStyle';
import Spinner from '@/components/atoms/Spinner';
import TextComponent from '@/components/atoms/TextComponent';
import TogglePanel from '@/components/atoms/TogglePanel';
import FooterComponent from '@/components/molecules/Footer';
import { ReportHeader } from '@/components/molecules/ReportHeader';
import { SideFilterSearchParams } from '@/components/molecules/SideFilterSearchParams';
import {
  CardsFontVariant,
  CardsVariant,
  Criticity,
  SummaryBigCards
} from '@/components/molecules/SummaryBigCards';
import {
  convertToMM,
  convertToNameNValue,
  convertToSummaryBigCards,
  NestedStructureItem,
  SummaryBigCardsStructure
} from '@/constants/view/types/structure.type';
import { useParams } from '@/hooks/Common/useParams';
import { getSPTDailyInterventionData, SPTData } from '@/services/bdos.services';
import { generateHashByCFs } from '@/utils/customFields';
import {
  applyOnNestedValue,
  getGridNestedValue,
  getNestedValue,
  setNestedValue
} from '@/utils/table';
import { useQuery } from '@tanstack/react-query';
import { addHours, endOfDay, endOfToday, format } from 'date-fns';
import { ReactNode, useRef, useState } from 'react';
import { BiSolidPieChart } from 'react-icons/bi';
import { CiCalendar } from 'react-icons/ci';
import { FaCheckCircle } from 'react-icons/fa';
import { FaCircleInfo } from 'react-icons/fa6';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { useReactToPrint } from 'react-to-print';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import H2 from '@/components/atoms/H2';
import Label2 from '@/components/atoms/Label2';

const PARENT = 'parent';
const BDO = 'bdo';
const SITOP = 'sitop';
const OLDEST_BDO = 'oldest_bdo';
const OLDEST_SITOP = 'oldest_sitop';
const REJECT_REASONS = 'reject_reasons';
const MOCS = 'mocs';
const IS_LATE = 'isLate';
const PROBE = 'probe';
const OBJECTIVE_ELAPSED_DAYS = 'objective_elapsed_days'; // For now is unecessary, if nothing changed we can remove it
const ACTUAL_OBJECTIVE_DETAILS = 'actualObjectiveDetails';
const LAST_UPDATE_DATE = 'lastUpdateDate';
const CONCLUDED_BDO = 'concluded_bdo';
const GLOBAL_ESTIMATED_TIME = 'globalEstimatedTime';
const GLOBAL_ESTIMATED_TIME_TILL_NOW = 'globalEstimatedTimeTillNow';
const GLOBAL_ELAPSED_DAYS = 'globalElapsedDays';
const CRITICIDADE_GG_RESERVATORIOS = 'criticity_gg_reservoirs';
const SITOP_PREVISAO_DTM_ATUAL = 'sitop_previsao_dtm_atual';

const SPT_COLETA_INFORMACOES = 3798;
export const SPT_DESCRICAO_OBJETICOS = 3791;
export const INDICE_OBJETIVO_ATUAL = 3801;
export const SITOP_OBJETIVO_ATUAL = 3800;
const POTENCIAL_UPSIDE = 3794;
const GAS_RECUPERAVEL = 3792;
const OLEO_RECUPERAVEL = 3793;
const CRITICIDADE_TECNICA = 3795;
const ETAPA_INTERVENTION_CF_ID = 3808;
const ESPECIFICACAO_OPBJETIVOS_CF_ID = 3803;
const ESTIMATEDDURATIONDAYS_CF_ID = 3312;
const START_DATE_CF_ID = 248;
const SITOP_DATA_INICIAL_CF_ID = 3311;
const CRITICIDADE_RESERVATORIOS = 3772;
const SITOP_DTM_SERA_MANTIDA_CF_ID = 3270;
const SITOP_DTM_ATUAL_CF_ID = 3268;
const SITOP_NOVO_DTM_CF_ID = 3271;
const CRITICIDADE_GG = 3796;

export const INTERVENTION_CUSTOM_FIELDS = [
  4,
  START_DATE_CF_ID,
  3193,
  3194,
  3195,
  3196,
  3197,
  3198,
  3214,
  3215,
  3216,
  3218,
  3220,
  3221,
  3222,
  3223,
  3225,
  3212,
  3226,
  3230,
  3235,
  3247,
  3254,
  3260,
  3262,
  3263,
  3264,
  SITOP_DTM_SERA_MANTIDA_CF_ID,
  SITOP_DTM_ATUAL_CF_ID,
  SITOP_NOVO_DTM_CF_ID,
  3272,
  3274,
  3276,
  3277,
  3278,
  3293,
  SITOP_DATA_INICIAL_CF_ID,
  ESTIMATEDDURATIONDAYS_CF_ID,
  3314,
  3315,
  3316,
  3320,
  3321,
  3333,
  3339,
  3376,
  3384,
  3393,
  3397,
  3455,
  3492,
  3493,
  INDICE_OBJETIVO_ATUAL,
  SPT_COLETA_INFORMACOES,
  SITOP_OBJETIVO_ATUAL,
  SPT_DESCRICAO_OBJETICOS,
  ETAPA_INTERVENTION_CF_ID,
  ESPECIFICACAO_OPBJETIVOS_CF_ID,
  CRITICIDADE_GG,
  CRITICIDADE_RESERVATORIOS,
  CRITICIDADE_TECNICA,
  POTENCIAL_UPSIDE
];

type InterventionReportData = {
  probe: string;
  parent: SPTData;
  bdo: SPTData;
  sitop: SPTData;
  oldest_bdo: SPTData;
  concluded_bdo: SPTData;
  oldest_sitop: SPTData;
  reasons_to_reject: string | null;
  mocs: string | null;
  objective_elapsed_days: number | null;
};

const LEGEND_INFO =
  'Poço considerado estratégico para geração de informações para Reservatórios';

const reportHeaderStructure: NestedStructureItem = [
  {
    id: `${SITOP}.3263`,
    name: 'Poço Atual',
    primitiveType: 'string',
    className: 'text-sm'
  },
  {
    id: `${SITOP}.3196`,
    name: `Elaborador`,
    primitiveType: 'string',
    className: 'text-sm'
  },
  {
    id: `${PARENT}.${SITOP_DATA_INICIAL_CF_ID}`,
    name: 'Data de Início',
    primitiveType: 'date',
    className: 'text-sm'
  },
  {
    id: `${SITOP}.3264`,
    name: 'Próximo Poço',
    primitiveType: 'string',
    className: 'text-sm'
  },
  {
    id: `${PARENT}.${SPT_COLETA_INFORMACOES}`,
    name: 'Coleta informações',
    primitiveType: 'string',
    legend: [
      {
        value: 'Sim',
        info: LEGEND_INFO
      }
    ],
    className: 'text-sm'
  }
];

const summaryBigCardsStructure: SummaryBigCardsStructure = [
  {
    name: 'Previsão de DTM',
    structure: [
      {
        id: `${OLDEST_SITOP}.3268`,
        name: 'Inicial',
        primitiveType: 'shortDate'
      },
      {
        id: `${SITOP_PREVISAO_DTM_ATUAL}`,
        name: 'Atual',
        primitiveType: 'shortDate'
      }
    ],
    priority: 'high',
    font: CardsFontVariant.Small,
    className: 'print:col-span-2'
  },
  {
    name: 'Duração (dias)',
    structure: [
      {
        name: 'Estimada',
        id: `${ACTUAL_OBJECTIVE_DETAILS}.${ESTIMATEDDURATIONDAYS_CF_ID}`,
        primitiveType: 'shortNumber'
      },
      {
        name: 'Realizada',
        id: `${GLOBAL_ELAPSED_DAYS}`,
        primitiveType: 'shortNumber'
      }
    ],
    priority: 'high',
    font: CardsFontVariant.Small,
    className: 'print:col-span-2 print:col-start-3'
  },
  {
    name: 'Formação/Zona',
    structure: [
      {
        name: '01',
        id: `${ACTUAL_OBJECTIVE_DETAILS}.3331`,
        primitiveType: 'string'
      },
      {
        name: '02',
        id: `zona_02.cf_id`,
        primitiveType: 'string'
      }
    ],
    priority: 'high',
    font: CardsFontVariant.Small,
    className: 'print:col-span-2 print:col-start-5'
  },
  {
    name: 'Custo (R$ MM)',
    structure: [
      {
        name: 'Estimada',
        id: `${PARENT}.3376`,
        primitiveType: 'number'
      },
      {
        name: 'Realizada',
        id: `${SITOP}.3455`,
        primitiveType: 'number'
      }
    ],
    priority: 'high',
    font: CardsFontVariant.Small,
    className: 'print:col-span-2 print:col-start-7'
  },
  {
    name: 'Potencial Upside',
    structure: [
      {
        name: '',
        id: `${ACTUAL_OBJECTIVE_DETAILS}.${POTENCIAL_UPSIDE}`,
        primitiveType: 'string'
      }
    ],
    priority: 'medium',
    font: CardsFontVariant.XSmall,
    variant: CardsVariant.ValueTagged,
    textColor: 'text-[#9F7A06]',
    bgColor: 'bg-[#FFF5D6]',
    className: 'justify-evenly print:col-span-2 print:col-start-9'
  },
  {
    name: 'Método de elevação',
    structure: [
      {
        name: '',
        id: `${ACTUAL_OBJECTIVE_DETAILS}.3382`,
        primitiveType: 'string'
      }
    ],
    priority: 'medium',
    font: CardsFontVariant.XSmall,
    className: 'justify-evenly print:col-span-2 print:row-start-2'
  },
  {
    name: 'Gás (MMm³/d)',
    structure: [
      {
        name: 'Vazão estimada',
        id: `${ACTUAL_OBJECTIVE_DETAILS}.3315`,
        primitiveType: 'shortNumber'
      }
    ],
    priority: 'medium',
    font: CardsFontVariant.Small,
    className: 'print:col-span-2 print:col-start-3 print:row-start-2'
  },
  {
    name: 'Óleo (bbl/d)',
    structure: [
      {
        name: 'Vazão estimada',
        id: `${ACTUAL_OBJECTIVE_DETAILS}.3314`,
        primitiveType: 'shortNumber'
      }
    ],
    priority: 'medium',
    font: CardsFontVariant.Small,
    className: 'print:col-span-2 print:col-start-5 print:row-start-2'
  },
  {
    name: 'Total (boe/d)',
    structure: [
      {
        name: 'Vazão estimada',
        id: `${ACTUAL_OBJECTIVE_DETAILS}.3317`,
        primitiveType: 'shortNumber'
      }
    ],
    priority: 'medium',
    font: CardsFontVariant.Small,
    className: 'print:col-span-2 print:col-start-7 print:row-start-2'
  },
  {
    name: 'Água (bwpd)',
    structure: [
      {
        name: 'Vazão estimada',
        id: `${ACTUAL_OBJECTIVE_DETAILS}.3316`,
        primitiveType: 'shortNumber'
      }
    ],
    priority: 'medium',
    font: CardsFontVariant.Small,
    className: 'print:col-span-2 print:col-start-9 print:row-start-2'
  },
  {
    name: 'Volume recuperável',
    structure: [
      {
        name: 'Gás (MMm³)',
        id: `${ACTUAL_OBJECTIVE_DETAILS}.${GAS_RECUPERAVEL}`,
        primitiveType: 'shortNumber'
      },
      {
        name: 'Óleo (kbbl)',
        id: `${ACTUAL_OBJECTIVE_DETAILS}.${OLEO_RECUPERAVEL}`,
        primitiveType: 'shortNumber'
      }
    ],
    priority: 'medium',
    font: CardsFontVariant.Small,
    className: 'print:col-span-5 print:row-start-3'
  },
  {
    name: 'Níveis de Criticidade',
    structure: [
      {
        name: 'Técnica',
        id: `${PARENT}.${CRITICIDADE_TECNICA}`
      },
      {
        name: 'G&G/Reservatórios',
        id: `${CRITICIDADE_GG_RESERVATORIOS}`
      }
    ],
    variant: CardsVariant.Criticity,
    priority: 'low',
    font: CardsFontVariant.Small,
    className: 'print:col-span-5 print:col-start-6 print:row-start-3'
  }
];

export const InterventionReport = () => {
  const { setParam, params } = useParams();
  const date = params.get('date') || format(endOfToday(), 'yyyy-MM-dd');

  const { data, isFetching } = useQuery(
    ['intervention-report', date],
    () => getSPTDailyInterventionData(date, INTERVENTION_CUSTOM_FIELDS),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: true
    }
  );

  const [onPrint, setOnPrint] = useState(false);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setOnPrint(false);
    }
  });

  const dataParseds = data?.map(item => parseData(item, new Date(date))) || [];

  const lastUpdateDates =
    dataParseds
      ?.map(item => new Date(item?.[LAST_UPDATE_DATE] || '').getTime())
      ?.filter(date => !isNaN(date)) || [];
  const lastUpdateDate = new Date(Math.max(...lastUpdateDates));

  return (
    <PageWrapper className="overflow-y-scroll">
      <div className="flex justify-end absolute right-12 -top-10">
        <a
          className="w-fit"
          href={`${
            import.meta.env.VITE_REDMINE_URL
          }/projects/boletim-diario-de-operacoes-intervencao/issues/new?issue[tracker_id]=57`}
          target="_blank"
        >
          <Button
            className="w-full"
            title="Nova Intervenção"
            type="button"
            icon={<HiOutlinePlusSm className="w-5 h-5" color="white" />}
          />
        </a>
      </div>
      <div ref={componentRef} className="bdmf-print mt-1 pb-6 px-1">
        <PrintStyle />
        <img
          src="/origem.png"
          alt="Logo Origem"
          className="w-[203px] resize-none max-w-none -ml-5 only-print"
        />

        <H1>
          Relatório Diário de Intervenção - CIP
          <button
            onClick={handlePrint}
            className="text-base font-medium mr-1 px-2 py-1 rounded border no-print"
            onMouseEnter={() => {
              setOnPrint(true);
            }}
            onMouseLeave={() => {
              setOnPrint(false);
            }}
          >
            Imprimir
          </button>
        </H1>

        <ReportHeader
          nestedValues={[
            {
              name: 'Emissão do Relatório',
              value: format(addHours(date, 3), 'dd/MM/yyyy')
            },
            {
              name: 'Última Atualização',
              value:
                lastUpdateDate.toString() !== 'Invalid Date'
                  ? format(lastUpdateDate, 'dd/MM/yyyy HH:mm')
                  : ''
            }
          ]}
        />

        <div className="flex flex-col mt-2 gap-2">
          {isFetching ? (
            <Spinner size={16} />
          ) : (
            dataParseds.map(dataParsed => {
              const actualObjectiveNumber = getNestedValue(
                dataParsed,
                `${SITOP}.${SITOP_OBJETIVO_ATUAL}`
              );

              return dataParsed ? (
                <TogglePanel
                  buttonClassName="bg-white hover:bg-white hover:ring-1 hover:ring-persian-blue-500 break-inside-auto break-after-page"
                  titleComponent={isOpen =>
                    InterventionTitleComponent({
                      probe: dataParsed.probe,
                      isLate: getNestedValue(dataParsed, IS_LATE),
                      sitopStatus: getNestedValue(
                        dataParsed,
                        `${SITOP}.status_id`
                      )
                    })
                  }
                  childrenClassName="max-h-none"
                  key={dataParsed.probe}
                  isPrinting={onPrint}
                >
                  <div className="bg-white py-3 rounded-lg flex flex-col flex-1">
                    <div className="border border-gray/20 rounded break-inside-avoid">
                      <ReportHeader
                        nestedValues={convertToNameNValue(
                          reportHeaderStructure,
                          dataParsed
                        )}
                        key={`${dataParsed.probe}.report-header`}
                      />
                    </div>

                    <div className="items-center flex-row gap-1 p-2 text-sm font-bold text-gray-dark hidden print:flex">
                      <FaCircleInfo className="text-sm text-primary" />
                      <div>{LEGEND_INFO}</div>
                    </div>

                    <div className="flex-col mt-6">
                      <div className="flex gap-2 items-center">
                        <div className="font-bold text-primary text-xl">
                          Descrição do Objetivo
                        </div>
                        {actualObjectiveNumber ? (
                          <div className="rounded py-1 px-2 font-bold text-xs text-primary bg-[#D9E1FF]">
                            Objetivo {actualObjectiveNumber}
                          </div>
                        ) : null}
                      </div>

                      <TextComponent
                        value={getNestedValue(
                          dataParsed,
                          `${ACTUAL_OBJECTIVE_DETAILS}.3198`
                        )}
                      />
                    </div>

                    <div className="mt-2 mb-6 break-inside-avoid">
                      <SummaryBigCards
                        data={convertToSummaryBigCards(
                          summaryBigCardsStructure,
                          dataParsed
                        )}
                        key="SummaryBigCards"
                        wrapperClassName="print:grid print:grid-cols-10 print:grid-rows-3"
                      />
                    </div>

                    <Section
                      name={'SITOP'}
                      label={Label2}
                      id={`${SITOP}.3276.label`}
                      key={`${SITOP}.3276.label`}
                    >
                      <div className="space-y-0">
                        <Section
                          label={H2}
                          name="Principais Informações:"
                          id="label.h2.sitop_section"
                          key="label.h2.sitop_section"
                        >
                          <TextComponent
                            value={getNestedValue(dataParsed, `${SITOP}.3276`)}
                            label="Últimas 12 horas"
                            key={`${SITOP}.3276`}
                          />

                          <TextComponent
                            value={getNestedValue(dataParsed, `${SITOP}.3277`)}
                            label="Atual"
                            key={`${SITOP}.3277`}
                          />

                          <TextComponent
                            value={getNestedValue(dataParsed, `${SITOP}.3278`)}
                            label="Próximas 12 horas"
                            key={`${SITOP}.3278`}
                          />
                        </Section>
                      </div>

                      <div className="flex-col break-inside-avoid">
                        <h2 className="font-bold text-lg text-primary p-2 border border-gray/20">
                          <div className="flex flex-row items-center gap-1">
                            Listagem de Operações
                            <div
                              data-tooltip-id={`tooltip-Listagem de Operações-${dataParsed[PROBE]}`}
                            >
                              <FaCircleInfo className="text-primary" />

                              <ReactTooltip
                                id={`tooltip-Listagem de Operações-${dataParsed[PROBE]}`}
                                place="bottom-start"
                                style={{
                                  maxWidth: '250px',
                                  textAlign: 'left',
                                  backgroundColor: '#F1F1F1'
                                }}
                              >
                                <div className="text-sm font-bold text-bold text-primary">
                                  Legenda
                                </div>
                                <InterventionStepsSymbolLegend />
                              </ReactTooltip>
                            </div>
                          </div>
                        </h2>

                        <div className="px-4 my-3 grid grid-cols-3">
                          {getGridNestedValue(
                            dataParsed,
                            `${SITOP}.${ETAPA_INTERVENTION_CF_ID}`
                          ).map((line, index) => (
                            <div
                              key={`${line[3274]}.operations.${line[3408]}`}
                              className="flex flex-row gap-1 h-14"
                            >
                              <span className="font-bold w-6">
                                {line[3408]}.
                              </span>
                              {ParseStepStatus2Symbol(line[3807])}
                              <span className="w-80">{line[3274]}</span>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="items-center p-2 flex-row gap-1 hidden print:flex">
                        <div className="text-sm font-bold text-bold text-primary">
                          Legenda:
                        </div>
                        <InterventionStepsSymbolLegend />
                      </div>
                    </Section>

                    <Section
                      name={'Observações'}
                      label={Label2}
                      id={'Observações.label'}
                      key={'Observações.label'}
                      className="bg-[#EAEEFF]"
                    >
                      <TextComponent
                        value={getNestedValue(dataParsed, `${REJECT_REASONS}`)}
                        key={`${REJECT_REASONS}`}
                      />
                    </Section>

                    <Section
                      name={'Linhas de Produção/Injeção'}
                      label={Label2}
                      id={'Linhas de Produção/Injeção.label'}
                      key={'Linhas de Produção/Injeção.label'}
                      className="bg-[#EAEEFF]"
                    >
                      <TextComponent
                        value={getNestedValue(dataParsed, `${PARENT}.3321`)}
                        key={`${PARENT}.3321`}
                      />
                    </Section>

                    <Section
                      name={'MOCs'}
                      label={Label2}
                      id={'MOCs.label'}
                      key={'MOCs.label'}
                      className="bg-[#EAEEFF]"
                    >
                      <TextComponent
                        value={getNestedValue(dataParsed, `${MOCS}`)}
                        key={`${MOCS}.${dataParsed.probe}`}
                      />
                    </Section>
                  </div>
                </TogglePanel>
              ) : null;
            })
          )}
        </div>
        <SideFilterSearchParams
          dateSearchParams={[{ name: 'Data', value: date, keyValue: 'date' }]}
        />
      </div>
    </PageWrapper>
  );
};

const parseData = (
  data: InterventionReportData | undefined,
  searchDate: Date
) => {
  if (!data) {
    return undefined;
  }

  const dataAsHash = parse2Hash(data);

  setActualObjectiveDetailsNGlobalEstimatedTime(dataAsHash, searchDate);
  setCriticity(dataAsHash);
  setSitopPrevisaoDtmAtual(dataAsHash);
  setClosestSITOPStatus(dataAsHash, data);
  applyDataConversions(dataAsHash);
  setIsLate(dataAsHash, searchDate);

  return dataAsHash;
};

const parse2Hash = (data: InterventionReportData) => {
  return {
    [PROBE]: data.probe,
    [PARENT]: generateHashByCFs(data.parent?.custom_fields || []),
    [BDO]: generateHashByCFs(data.bdo?.custom_fields || []),
    [SITOP]: generateHashByCFs(data.sitop?.custom_fields || []),
    [OLDEST_BDO]: generateHashByCFs(data.oldest_bdo?.custom_fields || []),
    [OLDEST_SITOP]: generateHashByCFs(data.oldest_sitop?.custom_fields || []),
    [LAST_UPDATE_DATE]: data.sitop?.issue?.updated_on,
    [REJECT_REASONS]: data.reasons_to_reject,
    [MOCS]: data.mocs,
    [OBJECTIVE_ELAPSED_DAYS]: data.objective_elapsed_days
  };
};

const setActualObjectiveDetailsNGlobalEstimatedTime = (
  dataAsHash: Record<string, any>,
  searchDate: Date
) => {
  const extractObjectiveNumber = (str?: string) => str?.split('-')[0]?.trim();

  const actualObjectiveTag = extractObjectiveNumber(
    getNestedValue(dataAsHash, `${SITOP}.${SITOP_OBJETIVO_ATUAL}`)
  );

  setNestedValue(
    dataAsHash,
    `${SITOP}.${SITOP_OBJETIVO_ATUAL}`,
    actualObjectiveTag
  );

  const objectiveDetails = getGridNestedValue(
    dataAsHash,
    `${PARENT}.${SPT_DESCRICAO_OBJETICOS}`
  );
  const actualObjetctiveDetails = objectiveDetails.find(
    objective => objective[INDICE_OBJETIVO_ATUAL] === actualObjectiveTag
  );

  const especificacaoObjetivos = getGridNestedValue(
    dataAsHash,
    `${PARENT}.${ESPECIFICACAO_OPBJETIVOS_CF_ID}`
  );
  const actualObjectiveDetailsWithEspecificacaoObjetivos =
    especificacaoObjetivos.find(
      especificacao =>
        especificacao[INDICE_OBJETIVO_ATUAL] === actualObjectiveTag
    );

  const mergedObjectiveDetails = {
    ...actualObjetctiveDetails,
    ...actualObjectiveDetailsWithEspecificacaoObjetivos
  };

  const globalEstimatedTime = aggregateEstimatedTime(objectiveDetails);
  const globalEstimatedTillNow = actualObjectiveTag
    ? getGlobalEstimatedTillNow(actualObjectiveTag, objectiveDetails)
    : undefined;
  const globalElapsedDaysTillNow = getGlobalElapsedDays(dataAsHash, searchDate);

  setNestedValue(dataAsHash, ACTUAL_OBJECTIVE_DETAILS, mergedObjectiveDetails);
  setNestedValue(dataAsHash, GLOBAL_ESTIMATED_TIME, globalEstimatedTime);
  setNestedValue(
    dataAsHash,
    GLOBAL_ESTIMATED_TIME_TILL_NOW,
    globalEstimatedTillNow
  );
  setNestedValue(dataAsHash, GLOBAL_ELAPSED_DAYS, globalElapsedDaysTillNow);
};

function getGlobalEstimatedTillNow(
  actualObjectiveTag: string,
  objectiveDetails: { [key: string]: any }[]
): number | undefined {
  const actualObjetctiveIndex = objectiveDetails.findIndex(
    objective => objective[INDICE_OBJETIVO_ATUAL] === actualObjectiveTag
  );

  return aggregateEstimatedTime(
    objectiveDetails.slice(0, actualObjetctiveIndex + 1)
  );
}

const setCriticity = (dataAsHash: Record<string, any>) => {
  const criticity_gg = getNestedValue(
    dataAsHash,
    `${PARENT}.${CRITICIDADE_GG}`
  );
  const criticity_reservoirs = getNestedValue(
    dataAsHash,
    `${PARENT}.${CRITICIDADE_RESERVATORIOS}`
  );
  const criticity = max_of_criticity(criticity_gg, criticity_reservoirs);

  setNestedValue(dataAsHash, CRITICIDADE_GG_RESERVATORIOS, criticity);
};

const setSitopPrevisaoDtmAtual = (dataAsHash: Record<string, any>) => {
  let dtmAtual = getNestedValue(
    dataAsHash,
    `${SITOP}.${SITOP_DTM_ATUAL_CF_ID}`
  );

  const dtmSeraMantida = getNestedValue(
    dataAsHash,
    `${SITOP}.${SITOP_DTM_SERA_MANTIDA_CF_ID}`
  );

  if (dtmSeraMantida === 'Não') {
    dtmAtual = getNestedValue(dataAsHash, `${SITOP}.${SITOP_NOVO_DTM_CF_ID}`);
  }

  setNestedValue(dataAsHash, SITOP_PREVISAO_DTM_ATUAL, dtmAtual);
};

function aggregateEstimatedTime(
  objectiveDetails: { [key: string]: any }[]
): number {
  return objectiveDetails.reduce((acc, curr) => {
    return acc + parseFloat(curr[ESTIMATEDDURATIONDAYS_CF_ID] || '0');
  }, 0);
}

const setClosestSITOPStatus = (
  dataAsHash: Record<string, any>,
  data: InterventionReportData
) => {
  setNestedValue(
    dataAsHash,
    `${SITOP}.status_id`,
    data.sitop?.issue?.status_id
  );
};

const setIsLate = (dataAsHash: Record<string, any>, searchDate: Date) => {
  setNestedValue(dataAsHash, IS_LATE, getIsLate(dataAsHash, searchDate));
};

const applyDataConversions = (dataAsHash: Record<string, any>) => {
  applyOnNestedValue(dataAsHash, `${PARENT}.3376`, convertToMM);
  applyOnNestedValue(dataAsHash, `${SITOP}.3455`, convertToMM);
  applyOnNestedValue(dataAsHash, `${ACTUAL_OBJECTIVE_DETAILS}.3317`, parseInt);
  applyOnNestedValue(
    dataAsHash,
    `${REJECT_REASONS}`,
    replacePipeWithEmptyString
  );

  applyOnNestedValue(
    dataAsHash,
    `${ACTUAL_OBJECTIVE_DETAILS}.3382`,
    elevationMethod =>
      elevationMethod?.toString()?.toUpperCase() === 'S'
        ? 'Surgente'
        : elevationMethod
  );
};

const Section = (
  props: LabelProps & {
    children: ReactNode;
    label: (labelProps: LabelProps) => ReactNode;
  }
) => {
  const LabelComponent = props.label;
  return (
    <div className="flex-col break-inside-avoid">
      <LabelComponent {...props} />
      {props.children}
    </div>
  );
};

const InterventionTitleComponent = ({
  probe,
  isLate,
  sitopStatus
}: {
  probe: string;
  isLate: boolean;
  sitopStatus: number;
}) => {
  const statusInfo = statusIdToDisplayInfo(sitopStatus);
  return (
    <div className="flex w-full justify-between mr-4">
      <div className="flex items-center gap-1">
        <LateClock
          id={`${probe}-lateClock`}
          late={isLate ? LateOptions.Late : LateOptions.NotLate}
        />
        <span>{probe}</span>
      </div>

      <span
        className={twMerge(
          'rounded-full text-[10px] min-w-[156px] px-7 font-semibold content-center',
          statusInfo.color
        )}
      >
        {statusInfo.standardName}
      </span>
    </div>
  );
};

const InterventionStepsSymbolLegend = () => {
  return (
    <>
      <div className="flex items-center gap-1">
        <FaCheckCircle className="text-xl text-[#10AE88]/50" />
        <span className="text-sm text-body-dark">Operação Finalizada</span>
      </div>

      <div className="flex items-center gap-1">
        <BiSolidPieChart className="text-xl text-primary/25" />
        <span className="text-sm text-body-dark">Operação em andamento</span>
      </div>

      <div className="flex items-center gap-1">
        <div className="flex items-center justify-center text-gray-dark min-w-[20px] w-5 h-5 bg-[#E7E7E7] rounded-full">
          <CiCalendar className="py-0.1" />
        </div>
        <span className="text-sm text-body-dark">Operação prevista</span>
      </div>
    </>
  );
};

const ParseStepStatus2Symbol = (status: string) => {
  switch (status) {
    case 'Concluída':
      return (
        <FaCheckCircle className="min-w-[20px] text-xl text-[#10AE88]/50" />
      );
    case 'Atual':
      return (
        <BiSolidPieChart className="min-w-[20px] text-xl text-primary/25" />
      );
    case 'Não iniciada':
    default:
      return (
        <div className="flex items-center justify-center min-w-[20px] w-5 h-5 bg-[#E7E7E7] rounded-full">
          <CiCalendar className="py-0.1" />
        </div>
      );
  }
};

const statusIdToDisplayInfo = (statusId: number) => {
  switch (statusId) {
    case 139:
      return {
        standardName: 'Em Preenchimento', // Execução da Intervenção
        color: 'bg-[#D7DFFF]/40 text-[#193CB9]'
      };
    case 149:
      return {
        standardName: 'Validação Engenharia', // Validação do SITOP
        color: 'bg-[#FFE8D7]/40 text-[#EC7100]'
      };
    case 140:
      return {
        standardName: 'Aprovado', // SITOP Concluido
        color: 'bg-[#D0FFE1] text-[#5F9573]'
      };
    default:
      return {
        standardName: '',
        color: ''
      };
  }
};

const getIsLate = (dataAsHash: Record<string, any>, searchDate: Date) => {
  const elapsedDays = getGlobalElapsedDays(dataAsHash, searchDate);
  const concludedDate = getConcludedDate(dataAsHash);
  const estimatedDurationDays = parseFloat(
    getNestedValue(
      dataAsHash,
      `${ACTUAL_OBJECTIVE_DETAILS}.${ESTIMATEDDURATIONDAYS_CF_ID}`
    )
  );

  if (concludedDate) {
    return false;
  }

  return elapsedDays ? elapsedDays > estimatedDurationDays : false;
};

const getGlobalElapsedDays = (
  dataAsHash: Record<string, any>,
  searchDate: Date
) => {
  const startDate = getStartDate(dataAsHash);
  const concludedDate = getConcludedDate(dataAsHash);

  if (!startDate) {
    return undefined;
  }

  const startDateObj = new Date(startDate);

  let lastDate: Date;
  if (concludedDate) {
    lastDate = new Date(concludedDate);
  } else {
    lastDate = new Date(searchDate);
  }

  const d = Math.floor(
    (endOfDay(lastDate).getTime() - endOfDay(startDateObj).getTime()) /
      (1000 * 3600 * 24)
  );

  return d ? d + 1 : undefined;
};

const getConcludedDate = (dataAsHash: Record<string, any>) => {
  return getNestedValue(dataAsHash, `${CONCLUDED_BDO}.${START_DATE_CF_ID}`);
};

const getStartDate = (dataAsHash: Record<string, any>) => {
  return getNestedValue(dataAsHash, `${OLDEST_BDO}.${START_DATE_CF_ID}`);
};

const replacePipeWithEmptyString = (str?: string) => {
  return str?.replace(/\|/g, '');
};

function max_of_criticity(
  criticity_gg: Criticity,
  criticity_reservoirs: Criticity
): Criticity {
  const value_gg = getCriticityValue(criticity_gg);
  const value_reservoirs = getCriticityValue(criticity_reservoirs);

  if (value_gg >= value_reservoirs) {
    return criticity_gg;
  }
  return criticity_reservoirs;
}
function getCriticityValue(criticity: Criticity): number {
  switch (criticity) {
    case Criticity.High:
      return 3;
    case Criticity.Medium:
      return 2;
    case Criticity.Low:
      return 1;
    case Criticity.NotAvailable:
    default:
      return 0;
  }
}
