import Button from '@/components/atoms/Button';
import DropdownButton from '@/components/atoms/DropdownButton';
import SelectInputUncontrolled from '@/components/atoms/SelectInputUncontrolled';
import { objectify } from '@/constants/filters/WorflowTests';
import { Outlet } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const Schematic = () => {
  return (
    <>
      <div className="px-4 pb-1 pt-3 bg-white z-[11]">
        <h4 className="text-primary font-bold text-lg">Well Handover</h4>
      </div>

      <div className="flex flex-row w-full px-4 pt-2 pb-8 bg-white z-[11]">
        <div className="flex flex-col w-[30%] gap-1 me-3">
          <h3 className="text-sm font-bold text-slate-600">Poço</h3>

          <SelectInputUncontrolled
            isMulti={false}
            placeholder="Selecione o poço"
            options={['Poço 1', 'Poço 2'].map(objectify)}
            onChange={(val: any) => {
              console.log('Polo:', val);
            }}
          />
        </div>

        <div className="flex flex-col w-[20%] gap-1">
          <h3 className="text-sm font-bold text-slate-600">Operação</h3>
          <SelectInputUncontrolled
            isMulti={false}
            placeholder="Selecione a operação"
            options={['Operação 1', 'Operação 2'].map(objectify)}
            onChange={(val: any) => {
              console.log('Operação:', val);
            }}
          />
        </div>
      </div>

      <span className="flex border-gray-200 border-[1px] border-[#DFDFDF]"></span>

      <div className="flex flex-row w-full px-4 py-1 bg-white">
        <DropdownButton
          buttonText="Esquemáticos"
          links={[
            {
              label: 'Esquemático do poço',
              url: `/home/schematic/schematic-oilwell`
            },
            {
              label: 'Esquemático CSB',
              url: '#'
            }
          ]}
          nameId="schematic-menu-option"
          menuClassName="shadow-light"
          buttonClassName={`
            w-fit 
            hover:bg-btn-light-2 hover:text-primary-dark transition-all 
            transition-all duration-200
            text-primary
            text-sm
            font-bold
            px-5 py-2`}
          iconClassName={'text-primary'}
          linkClassName={`
            flex 
            items-center
            gap-6 py-2 px-3 mb-1
            hover:bg-btn-light rounded-md group 
            transition-all font-bold`}
        />

        <DropdownButton
          buttonText="Dados Estruturais"
          links={[
            {
              label: 'Geologia e reservatório',
              url: `#`
            },
            {
              label: 'Válvulas instaladas',
              url: '#'
            },
            {
              label: 'Envelope seguro de alteração',
              url: '#'
            }
          ]}
          nameId="schematic-menu-option"
          menuClassName="shadow-light"
          buttonClassName={`
            w-fit 
            hover:bg-btn-light-2 hover:text-primary-dark transition-all 
            transition-all duration-200
            text-primary
            text-sm
            font-bold
            px-5 py-2`}
          iconClassName={'text-primary'}
          linkClassName={`
            flex 
            items-center
            gap-6 py-2 px-3 mb-1
            hover:bg-btn-light rounded-md group 
            transition-all font-bold`}
        />

        <Button
          title={'Avaliações Periódicas'}
          className={twMerge(
            `flex items-center justify-center gap-2
            rounded-3xl 
            bg-transparent hover:bg-btn-light-2 hover:text-primary-dark transition-all 
            transition-all duration-200
            px-5 py-2`
          )}
          classNameSpan="text-primary text-sm font-bold"
        />

        <DropdownButton
          buttonText="CSB"
          links={[
            {
              label: 'Elementos CSBs',
              url: `#`
            },
            {
              label: 'Histórico de Verificação CSB',
              url: '#'
            },
            {
              label: 'Gerenciamento de CSB',
              url: '#'
            }
          ]}
          nameId="schematic-menu-option"
          buttonClassName={`
            w-fit 
            hover:bg-btn-light-2 hover:text-primary-dark transition-all 
            transition-all duration-200
            text-primary
            text-sm
            font-bold
            px-5 py-2`}
          iconClassName={'text-primary'}
          menuClassName="shadow-light"
          linkClassName={`
            flex 
            items-center
            gap-6 py-2 px-3 mb-1
            hover:bg-btn-light rounded-md group 
            transition-all font-bold`}
        />

        <Button
          title={'Avaliação global do poço'}
          className={twMerge(
            `flex items-center justify-center gap-2
            rounded-3xl 
            bg-transparent hover:bg-btn-light-2 hover:text-primary-dark transition-all 
            transition-all duration-200
            px-5 py-2`
          )}
          classNameSpan="text-primary text-sm font-bold"
        />
      </div>

      <section className="w-full h-[full] overflow-y-scroll scrollbar-thin scrollbar-thumb-[#D9D9D9AA] scrollbar-thumb-rounded-full ">
        <Outlet />
      </section>
    </>
  );
};

export default Schematic;
